import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import React from "react";
import { Route, BrowserRouter as Router, Routes, useNavigate } from "react-router-dom";
import ConfigApp from "./ConfigApp";
import OverrideApp from "./OverrideApp";
import DiagnosticApp from "./DiagnosticApp";
import NavBar from "./NavBar";
import config_data from "./config.json";

// Load outside the App to ensure it only loads once during module imports.
Amplify.configure(config_data.Amplify);

function App() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);

  // authenticate
  React.useEffect(() => {
    const checkUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (err) {
        localStorage.setItem("returnURL", window.location.pathname);

        // user is not authenticated, initiate SSO sign in
        // @ts-ignore
        Auth.federatedSignIn({ provider: "SSO" });
      } finally {
        setLoading(false);
      }
    };

    // check user on mount
    if (!!(config_data.Amplify as any).Auth) {
      console.log("authenticating");
      checkUser();
    } else {
      console.log("passing through");
      setIsAuthenticated(true);
      setLoading(false);
    }
  }, []);

  function RedirectToRegularFirmwareUpdate() {
    const navigate = useNavigate();

    React.useEffect(() => {
      const returnUrl = localStorage.getItem("returnURL");
      const navigateTo = returnUrl === "/" ? "/regular-firmware-update" : returnUrl;
      navigate(navigateTo || "/regular-firmware-update");
    }, [navigate]);

    return null;
  }

  return (
    <div>
      <Backdrop open={loading || !isAuthenticated}>
        <Box display="flex" flexDirection={"column"} alignItems="center">
          <CircularProgress color="inherit" />
          <Typography mt={2} variant="h6">
            Authenticating...
          </Typography>
        </Box>
      </Backdrop>
      {!loading && isAuthenticated && (
        <Router>
          <Box display="flex" flexDirection="column" height="100vh">
            <Box flexShrink={0}>
              <NavBar />
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
              <Routes>
                <Route path="/override-firmware-update" element={<OverrideApp />} />
                <Route path="/regular-firmware-update" element={<ConfigApp />} />
                <Route path="/device-diagnostics" element={<DiagnosticApp />} />
                <Route path="/" element={<RedirectToRegularFirmwareUpdate />} />
              </Routes>
            </Box>
          </Box>
        </Router>
      )}
    </div>
  );
}

export default App;
