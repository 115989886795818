import {
  Button,
  MenuItem,
  Autocomplete,
  Menu,
  IconButton,
  Snackbar,
  InputAdornment,
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React from "react";
import { StreamConfig, Status, Strategy } from "./Types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface ConfigCommonSettingsProps<T> {
  config: StreamConfig;
  onConfigChange: (updated: StreamConfig) => void;
  enablePriority: boolean;
  enableStrategy: boolean;
  enableForce: boolean;
  detailsTabEnum: T;
  detailsTab: T[keyof T];
  onDetailsTabChange: (tab: T[keyof T]) => void;
}

export const ConfigCommonSettings = <T extends object>(props: ConfigCommonSettingsProps<T>): JSX.Element => {
  const { config, onConfigChange, enablePriority, enableStrategy, enableForce, detailsTabEnum, detailsTab, onDetailsTabChange } = props;
  const detailsTabs = Object.values(detailsTabEnum);

  const [detailMenuAnchor, setDetailMenuAnchor] = React.useState<null | HTMLElement>(null);

  const [clipboardCopySnackOpen, setClipboardCopySnackOpen] = React.useState<boolean>(false);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(config.uid).then(
      () => {
        setClipboardCopySnackOpen(true);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      },
    );
  };

  return (
    <Box display="flex" flexGrow={1} flexDirection="column">
      <Grid container columns={2} spacing={1} padding={0}>
        <Grid item xs={2}>
          <TextField
            label="uid"
            size="small"
            variant="outlined"
            fullWidth
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={!config.uid} onClick={handleCopyToClipboard} edge="end">
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={config.uid || "New uuid will be generated"}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <TextField
            label="Name"
            size="small"
            variant="outlined"
            fullWidth
            required
            value={config.name || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let updated = { name: event.target.value };
              onConfigChange({ ...config, ...updated });
            }}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <Grid container columns={2} spacing={2}>
            <Grid item xs={2} sm={1}>
              <Autocomplete
                size="small"
                disableClearable
                disabled={!enablePriority}
                options={Array.from(Array(99)).map((_, i) => i + 1)}
                getOptionLabel={(option) => option.toString()}
                value={config.priority}
                onChange={(_, newValue: number) => {
                  let updated = { priority: newValue };
                  onConfigChange({ ...config, ...updated });
                }}
                renderInput={(params) => <TextField {...params} label="Priority" variant="outlined" size="small" />}
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <Autocomplete
                size="small"
                disableClearable
                options={Object.keys(Status)}
                value={config.status}
                onChange={(_, newValue: string) => {
                  let updated = {
                    status: Status[newValue as keyof typeof Status],
                  };
                  onConfigChange({ ...config, ...updated });
                }}
                renderInput={(params) => <TextField {...params} label="Status" variant="outlined" size="small" />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Description"
            size="small"
            variant="outlined"
            fullWidth
            multiline
            value={config.description || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let updated = { description: event.target.value };
              onConfigChange({ ...config, ...updated });
            }}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <Button
            variant="outlined"
            onClick={(event: React.MouseEvent<HTMLElement>) => setDetailMenuAnchor(event.currentTarget)}
          >
            {String(detailsTab)}
          </Button>
        </Grid>
        <Grid item xs={2} sm={1}>
          <Grid container columns={2} spacing={2}>
            <Grid item xs={2} sm={1}>
              <Autocomplete
                size="small"
                disableClearable
                disabled={!enableStrategy}
                options={Object.keys(Strategy)}
                value={config.strategy}
                onChange={(_, newValue: String) => {
                  let updated = {
                    strategy: Strategy[newValue as keyof typeof Strategy],
                  };
                  onConfigChange({ ...config, ...updated });
                }}
                renderInput={(params) => <TextField {...params} label="Strategy" variant="outlined" size="small" />}
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <Grid container justifyContent="center" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!enableForce}
                      checked={config.force}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        let updated = { force: event.target.checked };
                        onConfigChange({ ...config, ...updated });
                      }}
                    />
                  }
                  label="Force"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={clipboardCopySnackOpen}
        autoHideDuration={1000}
        onClose={() => setClipboardCopySnackOpen(false)}
        message={"Copied: " + config.uid}
      />
      <Menu
        anchorEl={detailMenuAnchor}
        open={Boolean(detailMenuAnchor)}
        onClose={() => setDetailMenuAnchor(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {detailsTabs.map((tab) => (
          <MenuItem
            key={tab}
            selected={tab === detailsTab}
            onClick={(_) => {
              onDetailsTabChange(tab);
              setDetailMenuAnchor(null);
            }}
          >
            {tab}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
