import DiagnosticIcon from "@mui/icons-material/BugReport";
import SpeakerIcon from "@mui/icons-material/Speaker";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { Button, AppBar, Box, Toolbar, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { NavPath } from "./Types";
import config_data from "./config.json";

function NavBar() {
  const navPaths: NavPath[] = [
    {
      path: "/regular-firmware-update",
      title: (env: string) => `HEOS Firmware Update [${env}]`,
      icon: <SpeakerIcon />,
      label: "update",
    },
    {
      path: "/override-firmware-update",
      title: (env: string) => `HEOS Firmware Override [${env}]`,
      icon: <DisplaySettingsIcon />,
      label: "override",
    },
    {
      path: "/device-diagnostics",
      title: (env: string) => `HEOS Device Diagnostic [${env}]`,
      icon: <DiagnosticIcon />,
      label: "diagnostic",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const currentTitle = navPaths.find((navPath) => navPath.path === location.pathname)?.title(config_data.ENV);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">{currentTitle}</Typography>
        <Box flexGrow={1} />
        {navPaths.map((navPath) => (
          <Button
            key={navPath.label}
            color="inherit"
            variant="outlined"
            startIcon={navPath.icon}
            onClick={() => navigate(navPath.path)}
            sx={{ margin: 1 }}
          >
            {navPath.label}
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
