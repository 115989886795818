import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { Divider, Button, Typography, Toolbar, Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import { ConfigFilterDevices } from "./ConfigFilterDevices";
import { ConfigProjections } from "./ConfigProjections";
import { ConfigsList } from "./ConfigsList";
import { DeviceModel, FilterDeviceCortege, FwImage, ProjectionCortege, Type, StreamConfig } from "./Types";
import {
  fetchConfigs,
  fetchIncludedDevices,
  fetchExcludedDevices,
  fetchWaitingDevices,
  fetchModels,
  fetchImages,
  mintConfig,
  addConfig,
  updateConfig,
  deleteConfig,
  checkConfig,
} from "./DataManagement";
import { ConfigCommonSettings } from "./ConfigCommonSettings";

function OverrideApp() {
  React.useEffect(() => {
    document.title = "HEOS Firmware Update configuration";
  }, []);

  // user wait
  const [userWait, setUserWait] = React.useState<boolean>(false);

  // get configs
  const [configs, setConfigs] = React.useState<StreamConfig[]>([]);
  const [configsLoading, setConfigsLoading] = React.useState<boolean>(false);
  async function loadConfigs() {
    setConfigsLoading(true);
    setConfigs(await fetchConfigs());
    setConfigsLoading(false);
  }
  React.useEffect(() => {
    loadConfigs();
  }, []);

  // get models
  const [models, setModels] = React.useState<DeviceModel[]>([]);
  const [modelsLoading, setModelsLoading] = React.useState<boolean>(false);
  async function loadModels() {
    setModelsLoading(true);
    setModels(await fetchModels());
    setModelsLoading(false);
  }
  React.useEffect(() => {
    loadModels();
  }, []);

  // get images
  const [images, setImages] = React.useState<FwImage[]>([]);
  const [imagesLoading, setImagesLoading] = React.useState<boolean>(false);
  async function loadImages() {
    setImagesLoading(true);
    setImages(await fetchImages());
    setImagesLoading(false);
  }
  React.useEffect(() => {
    loadImages();
  }, []);

  const [inEdit, setInEdit] = React.useState<StreamConfig>(mintConfig);
  const [configErrors, setConfigErrors] = React.useState<String[]>([]);

  async function newConfig() {
    setUserWait(true);
    inEdit.type = Type.OVERRIDE;
    let errors = await checkConfig(inEdit, images);
    if (errors.length > 0) {
      setConfigErrors(errors);
    } else {
      await addConfig(inEdit, images);
      setInEdit(mintConfig);
      loadConfigs();
    }
    setUserWait(false);
  }
  async function changeConfig() {
    setUserWait(true);
    inEdit.type = Type.OVERRIDE;
    let errors = await checkConfig(inEdit, images);
    if (errors.length > 0) {
      setConfigErrors(errors);
    } else {
      await updateConfig(inEdit, images);
      setInEdit(mintConfig);
      loadConfigs();
    }
    setUserWait(false);
  }
  async function removeConfig(configId: string) {
    setUserWait(true);
    await deleteConfig(configId);
    setInEdit(mintConfig);
    loadConfigs();
    setUserWait(false);
  }

  enum DetailsTab {
    DEVICES_INCLUDED = "Device inclusion",
    MAPPINGS = "Projections",
  }
  const [detailsTab, setDetailsTab] = React.useState(DetailsTab.DEVICES_INCLUDED);

  const preventConfigChange = () => {
    return inEdit.name.trim() === '' || userWait;
  }

  return (
    <Grid container columns={3} style={{ height: "100%" }}>
      <Grid item xs={1} style={{ display: "flex", flexDirection: "column", height: "100%" }} padding={1}>
        <Typography align="center" padding={1} variant="h6">
          Overrides
        </Typography>
        <ConfigsList
          rows={configs}
          includeType={Type.OVERRIDE}
          loading={configsLoading}
          onSelect={(config) => (config === undefined ? setInEdit(mintConfig) : setInEdit(config))}
          onListIncluded={async (config) => await fetchIncludedDevices(config.uid)}
          onListExcluded={async (config) => await fetchExcludedDevices(config.uid)}
          onListWaiting={async (config) => await fetchWaitingDevices(config.uid)}
          onDelete={(config) => removeConfig(config.uid)}
        />
      </Grid>
      <Grid item xs={2} style={{ display: "flex", flexDirection: "column", height: "100%" }} padding={1}>
        <Typography align="center" padding={1} variant="h6">
          {inEdit.uid ? "Selected Override" : "New Override"}
        </Typography>
        <ConfigCommonSettings
          config={inEdit}
          onConfigChange={(config) => setInEdit(config)}
          enablePriority={false}
          enableStrategy={true}
          enableForce={true}
          detailsTabEnum={DetailsTab}
          detailsTab={detailsTab}
          onDetailsTabChange={(tab) => setDetailsTab(tab)}
        />
        <Box padding={1}>
          <Divider variant="inset" flexItem />
        </Box>
        <Box height="100%" display="flex" flexDirection="column">
          <Box flexGrow={1} style={{ overflow: "auto" }}>
            {detailsTab === DetailsTab.DEVICES_INCLUDED && (
              <Box minHeight={300} height="100%">
                <ConfigFilterDevices
                  configId={inEdit.uid}
                  rows={inEdit.filters.devices.included}
                  onRowsChange={(newRows: FilterDeviceCortege[]) => {
                    setInEdit((prev: StreamConfig) => ({
                      ...prev,
                      filters: {
                        models: prev.filters.models,
                        devices: {
                          included: newRows,
                          excluded: prev.filters.devices.excluded,
                        },
                      },
                    }));
                  }}
                  loading={modelsLoading}
                />
              </Box>
            )}
            {detailsTab === DetailsTab.MAPPINGS && (
              <Box minHeight={300} height="100%">
                <ConfigProjections
                  configId={inEdit.uid}
                  models={models}
                  images={images}
                  rows={inEdit.projections}
                  onRowsChange={(newRows: ProjectionCortege[]) => {
                    setInEdit((prev: StreamConfig) => ({
                      ...prev,
                      projections: newRows,
                    }));
                  }}
                  loading={modelsLoading || imagesLoading}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" flexGrow={1} flexDirection="column">
          {configErrors.length > 0 &&
            configErrors.map((err, i) => (
              <Alert severity="error" onClose={() => setConfigErrors(configErrors.filter((_, j) => j !== i))}>
                {err}
              </Alert>
            ))}
        </Box>
        <Toolbar style={{ justifyContent: "center", flexShrink: 0 }}>
          {inEdit.uid ? (
            <Button variant="contained" startIcon={<UpgradeIcon />} disabled={preventConfigChange()} onClick={() => changeConfig()}>
              Update
            </Button>
          ) : (
            <Button variant="contained" startIcon={<AddIcon />} disabled={preventConfigChange()} onClick={() => newConfig()}>
              Add
            </Button>
          )}
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            disabled={!inEdit.uid}
            onClick={() => removeConfig(inEdit.uid)}
          >
            Delete
          </Button>
        </Toolbar>
      </Grid>
    </Grid>
  );
}

export default OverrideApp;
